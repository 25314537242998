const white = '#fff';
// const black = '#000';
const orange = '#e97121';
const darkOrange = '#c85c14';
const grey = '#b0b0b0';
const richBlack = '#131b23';
const blueJeans = '#00a6fb';

const colors = {
  background: richBlack,

  primary: orange,

  hr: blueJeans,

  border: orange,

  // Fonts
  heading: white,
  subheading: grey,
  body: grey,
  link: white,
  linkHover: orange,

  linkButton: orange,
  linkButtonHover: darkOrange,
};

export default colors;
